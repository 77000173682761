import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import OverLappingSpinner from "../../../common/Spinner/OverLappingSpinner";
import ErrorComponent from "../../../common/ErrorComponent";
import QoEFilter from "./QoEFilter";
import { useMount } from "../../../utils/genericCommon";
import { downloadAsCSVFile } from "../../../utils/common";
import { getTableColumns, setApplicationsData } from "./tableConfig";
import apiService from "../../../config/api-config";
import i18n from "amdi18n-loader!../../nls/i18n";

const [HbrTable, HbrPagination, HbrInput, HbrIcon, HbrButton, HbrTag] =
  reactWrapper([
    "hbr-table",
    "hbr-pagination",
    "hbr-input",
    "hbr-icon",
    "hbr-button",
    "hbr-tag"
  ]);
// default sort column and sort order
const defaultSort = ["usage", "desc"];
// maximum number of selections allowed in the table
const maxRowSelection = 5;

const Table = ({ history, globalFilter, rowSelect, setSelectedAppList, reporting }) => {
  const [loader, setLoader] = useState(true);
  const mount = useMount();
  const dataRef = useRef({
    timestamp: 0,
    error: null,
    noData: false,
    // sort field
    sort: [defaultSort],
    // qoe status selection
    qoeHealth: "all",
    // search text
    search: "",
    // records count
    itemCount: 0,
    // maximum number of selections allowed
    maxSelection: maxRowSelection,
    // selected records
    selections: []
  });
  const tableRef = useRef();
  const paginationRef = useRef();

  // event for dashboard table
  useEffect(() => {
    // set page number and page size after initial load of pagination component
    if (mount.initMount === true) {
      if (paginationRef.current !== null) {
        paginationRef.current.element.itemCount = 0;
        paginationRef.current.element.pageSize = reporting === true ? 50 : 30;
      }
    }
    // load table data
    if (rowSelect === false) getTableData(globalFilter, { page: 0 });
  }, [
    globalFilter.timeFilter.current_period[0],
    globalFilter.timeFilter.current_period[1],
    globalFilter.currentTimeStamp,
    globalFilter.selectedSite
  ]);

  // event for sidebar table
  useEffect(() => {
    if (rowSelect === true) {
      if (mount.initMount === false) setSelectedAppList([]);
      // load table data
      getTableData(globalFilter, { page: 0 });
    }
    mount.initMount = false;
  }, [
    globalFilter.sideBarTimeFilter.current_period[0],
    globalFilter.sideBarTimeFilter.current_period[1]
  ]);

  const getTableData = useCallback(async (gFilter, customProps) => {
    if (paginationRef.current !== null) {
      const timestamp = Date.now();
      // reset state values
      Object.assign(
        dataRef.current,
        { timestamp, error: null, noData: false }
      );
      // get payload
      const pagination = paginationRef.current.element;
      let pageNumber = null;
      if (customProps !== undefined) {
        if (Number.isInteger(customProps.page)) pageNumber = customProps.page;
      }
      const page = Number.isInteger(pageNumber) ? pageNumber : pagination.page;
      const v4Payload = rowSelect === true
        ? gFilter.globalV4PayloadSidebar
        : gFilter.globalV4Payload;
      const payload = {
        ...v4Payload,
        size: pagination.pageSize,
        offset: page * pagination.pageSize,
        sort: Object.fromEntries(dataRef.current.sort)
      };
      if (dataRef.current.qoeHealth !== "all") {
        payload.vqoe_status = dataRef.current.qoeHealth === "gray"
          ? ["unknown"]
          : [dataRef.current.qoeHealth];
      }
      if (dataRef.current.search !== "")
        payload.search = dataRef.current.search;
      setLoader(true);
      const res = await apiService.getApplicationTableList(payload);
      if (
        mount.mounted === true && timestamp === dataRef.current.timestamp
        && tableRef.current !== null && paginationRef.current !== null
      ) {
        let selections = [];
        if (
          res.errorObject instanceof Object === false
          && res.data instanceof Object && Array.isArray(res.data.data)
          && res.data.data.length > 0
        ) {
          const resData = res.data.data;
          // set applications data
          setApplicationsData(resData);
          // get selections
          selections = resData.slice(0, maxRowSelection).map(item => {
            item.checked = true;
            return item.application;
          });
          // set table data
          tableRef.current.element.source = resData;
          pagination.itemCount = res.data.count;
          if (Number.isInteger(pageNumber)) pagination.page = pageNumber;
        }
        else {
          if (res.errorObject instanceof Object)
            dataRef.current.error = res.errorObject;
          dataRef.current.noData = true;
          // set table data
          tableRef.current.element.source = [];
          pagination.itemCount = 0;
        }
        dataRef.current.itemCount = pagination.itemCount;
        // set selected applications list in sidebar table
        if (rowSelect === true) {
          const rowCount = tableRef.current.element.source.length;
          dataRef.current.maxSelection =
            (rowCount >= maxRowSelection || rowCount === 0)
              ? maxRowSelection
              : rowCount;
          dataRef.current.selections = selections;
          // set selection
          setSelectedAppList(selections);
          // update selection state of rows
          setTimeout(() => mount.mounted && resetSelection(tableRef, dataRef));
        }
        setLoader(false);
      }
    }
  }, []);

  const columns = useMemo(() => {
    const tableColumns = getTableColumns(history, globalFilter.selectedSite);
    // add selection column to sidebar table
    if (rowSelect === true) {
      // handle header row checkbox click
      const handleHeaderRowCheckboxClick = event => {
        if (tableRef.current !== null) {
          // update selections
          const checked = event.target.checked;
          // get check box elements in rows
          const hbrCheckboxes = tableRef.current.element
            .querySelectorAll("hbr-table-data hbr-checkbox");
          const selections = [];
          if (checked === true) {
            const maxSelection = dataRef.current.maxSelection;
            // select rows
            tableRef.current.element.source.forEach((item, index) => {
              if (index < maxSelection) {
                item.checked = true;
                selections.push(item.application);
              } else item.checked = false;
            });
            hbrCheckboxes.forEach(item => {
              item.checked = selections.includes(item.id) ? true : false;
              item.disabled = !item.checked;
            });
          }
          else {
            // unselect rows
            tableRef.current.element.source
              .forEach(item => item.checked = false);
            hbrCheckboxes.forEach(item => {
              item.checked = false;
              item.disabled = false;
            });
          }
          dataRef.current.selections = selections;
          setSelectedAppList(selections);
        }
      };
      // create header row checkbox
      const createHeaderRowCheckbox = createElement => {
        const rowCount = tableRef.current.element.source.length;
        const selectedItems = dataRef.current.selections;
        const maxSelection = dataRef.current.maxSelection;
        const checkboxNode = createElement("hbr-checkbox", {
          indeterminate:
            selectedItems.length > 0 && selectedItems.length < maxSelection,
          checked: selectedItems.length === maxSelection,
          disabled: rowCount === 0,
          "onHbr-click": handleHeaderRowCheckboxClick
        });
        return checkboxNode;
      };
      // handle data row checkbox click
      const handleDataRowCheckboxClick = (event, model) => {
        if (tableRef.current !== null) {
          const checked = event.target.checked;
          event.target.blur();
          const maxSelection = dataRef.current.maxSelection;
          // get selections
          const selections = tableRef.current.element.source
            .filter(item => item.checked === true);
          if (!(checked === true && selections.length >= maxSelection)) {
            // update selections
            model.checked = checked;
            selections.push(model.application);
            dataRef.current.selections = selections;
            resetSelection(tableRef, dataRef);
            const source = tableRef.current.element.source;
            tableRef.current.element.source = source;
            tableRef.current.element.refresh();
            setSelectedAppList(dataRef.current.selections);
          }
        }
      };
      // create data row checkbox
      const createDataRowCheckbox = (createElement, { model, rowIndex }) => {
        const selectedItems = dataRef.current.selections;
        const maxSelection = dataRef.current.maxSelection;
        const checkbox = createElement("hbr-checkbox", {
          checked: model.checked,
          disabled: selectedItems.length >= maxSelection && !model.checked,
          id: model.application,
          "data-rowIndex": rowIndex,
          "onHbr-click": event => handleDataRowCheckboxClick(event, model)
        });
        return checkbox;
      };

      tableColumns.unshift({
        prop: "checkbox",
        size: 40,
        canResize: false,
        pin: "colPinStart",
        columnProperties: () => ({
          class: "hbr-table-column-header-checkbox"
        }),
        columnTemplate: createHeaderRowCheckbox,
        cellProperties: () => ({
          class: { "hbr-table-column-checkbox": true }
        }),
        cellTemplate: createDataRowCheckbox
      });
    }
    return tableColumns;
  }, [globalFilter.selectedSite]);

  // load table data on qoe status selection
  const onQoEHealthChange = event => {
    dataRef.current.qoeHealth = event.currentTarget.value;
    getTableData(globalFilter, { page: 0 });
  };

  // load table data on search
  const onSearch = ({ target: { value } }) => {
    if (value !== dataRef.current.search) {
      dataRef.current.search = value;
      getTableData(globalFilter, { page: 0 });
    }
  };

  // update selection on tag list change
  const onTagRemove = tagName => {
    // get selections
    const selections =
      dataRef.current.selections.filter(item => item !== tagName);
    dataRef.current.selections = selections;
    if (tableRef.current !== null) {
      const source = tableRef.current.element.source;
      // update table selections
      if (Array.isArray(source)) {
        for (const item of source)
          if (item.application === tagName) {
            item.checked = false;
            break;
          }
        // update selection in table
        resetSelection(tableRef, dataRef);
        setSelectedAppList(selections);
      }
    }
  };

  const getExportData = useCallback(async v4Payload => {
    // get payload
    const payload = {
      ...v4Payload,
      sort: Object.fromEntries(dataRef.current.sort)
    };
    if (dataRef.current.qoeHealth !== "all") {
      payload.vqoe_status = dataRef.current.qoeHealth === "gray"
        ? ["unknown"]
        : [dataRef.current.qoeHealth];
    }
    if (dataRef.current.search !== "") payload.search = dataRef.current.search;
    const res = await apiService.getApplicationTableList(payload);
    if (
      res.errorObject instanceof Object === false
      && res.data instanceof Object
      && Array.isArray(res.data.data)
    ) {
      const resData = res.data.data;
      // get colum header row
      const columnTitles = columns.map(item => `"${item.name}"`);
      // exclude checkbox column header
      if (rowSelect === true) columnTitles.shift();
      const data = [columnTitles.join(",")];
      setApplicationsData(resData);
      // add records
      for (const item of resData) {
        data.push([
          `"${item.application}"`,
          `"${item.vqoe_score}"`,
          `"${item.vqoe_change}"`,
          `"${item.vqoe_site_count}"`,
          `"${item.application_class}"`,
          `"${item.application_family_long_name}"`,
          `"${item.usage}"`,
          `"${item.packet_loss}"`,
          `"${item.latency}"`,
          `"${item.jitter}"`
        ].join(","));
      }
      // download the csv file
      downloadAsCSVFile(data.join("\n"), i18n.applicationTableViewApplication);
    }
  }, []);

  return (
    <div
      className="table-container hbr-css__layout-col-md"
      data-cy="applicationTableView"
    >
      {loader === true && <OverLappingSpinner />}
      <div className="hbr-css__layout-col-md">
        <div className="hbr-css__text-heading-lg-bold">
          {rowSelect === true
            ? i18n.applicationTableTitleCustom
            : i18n.applicationTableViewApplication}
        </div>
        {reporting === false && (
          <>
            <QoEFilter
              value={dataRef.current.qoeHealth}
              onChange={onQoEHealthChange}
              loader={loader}
            />
            <div
              className="hbr-css__layout-row-xs hbr-css__layout-justify-between"
            >
              <div className="hbr-css__layout-row-xs">
                <HbrInput
                  placeholder="Search"
                  clearable
                  onHbr-clear={onSearch}
                  onkeydown={event => {
                    if (event.code === "Enter") onSearch(event);
                  }}
                  onHbr-blur={onSearch}
                  disabled={loader}
                >
                  <HbrIcon
                    slot="prefix"
                    name="magnifying-glass"
                    sentiment="neutral"
                  />
                </HbrInput>
                <div className="hbr-css__filter-description">
                  {`${dataRef.current.itemCount}${i18n.applicationTableCountText}`}
                </div>
              </div>
              <HbrButton
                variant="outline"
                onClick={() => getExportData(
                  rowSelect === true
                    ? globalFilter.globalV4PayloadSidebar
                    : globalFilter.globalV4Payload
                )}
                disabled={loader}
              >
                <HbrIcon slot="prefix" sentiment="interact" name="upload-simple" />
                {i18n.export}
              </HbrButton>
            </div>
          </>
        )}
      </div>
      <HbrTable
        ref={tableRef}
        columns={columns}
        className={reporting === true ? 'reportApplicationTable' : ''}
        readonly
        resize
        serverSide
        canFocus={false}
        onBeforesortingapply={event => {
          if (tableRef.current !== null) {
            event.preventDefault();
            const { column, order } = event.detail;
            if (order) dataRef.current.sort[0] = [column.prop, order];
            else {
              const sortOrder =
                column.prop === defaultSort[0] ? "asc" : defaultSort[1];
              dataRef.current.sort[0] = [defaultSort[0], sortOrder];
              const columnIndex =
                columns.findIndex(item => item.prop === defaultSort[0]);
              tableRef.current.element.updateColumnSorting(
                columns[columnIndex],
                rowSelect === true ? columnIndex - 1 : columnIndex,
                sortOrder
              );
            }
            getTableData(globalFilter, { page: 0 });
          }
        }}
        onBeforesourcesortingapply={event => {
          event.preventDefault();
        }}
      >
        {dataRef.current.noData === true && (
          <div slot="empty" className="hbr-css__table-empty-state">
            {dataRef.current.error === null ? (
              <>
                <HbrIcon size="75px" sentiment="neutral" name="empty-state" />
                <span className="hbr-css__text-secondary-sm">
                  {i18n.c360.tEmpty}
                </span>
              </>
            ) : (
              <ErrorComponent
                {...dataRef.current.error}
                className="small-dashlet-error"
                width="110px"
              />
            )}
          </div>
        )}
      </HbrTable>
      <HbrPagination
        ref={paginationRef}
        className={reporting === true ? 'reportPagination' : ''}
        orderArray={[1, 2, 0]}
        showAll
        onHbr-page-changed={event => {
          if (Number.isInteger(event.detail)) getTableData(globalFilter);
          else getTableData(globalFilter, { page: 0 });
        }}
        onHbr-size-changed={() => getTableData(globalFilter)}
      />
      {rowSelect === true && (
        <div>
          <hr />
          <div className="hbr-css__layout-row-2xs">
            <div className="hbr-css__filter-description">
              {dataRef.current.selections.length}
              {i18n.applicationTableSelectedText}
            </div>
            {dataRef.current.selections.map(item => (
              <div key={item}>
                <HbrTag removable onHbr-remove={() => onTagRemove(item)}>
                  {item}
                </HbrTag>
              </div>
            ))}
          </div>
          <hr />
        </div>
      )}
    </div>
  );
};

Table.defaultProps = {
  rowSelect: false,
  reporting: false
};

Table.propTypes = {
  history: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,
  rowSelect: PropTypes.bool,
  setSelectedAppList: PropTypes.func.isRequired,
  reporting: PropTypes.bool
};

const resetSelection = (tableRef, dataRef) => {
  if (tableRef.current !== null) {
    const maxSelection = dataRef.current.maxSelection;
    // get current selections
    const selections = tableRef.current.element.source
      .filter(item => item.checked === true)
      .map(item => item.application);
    const selectionCount = selections.length;
    // get select all check box in header
    const columnCheckbox = tableRef.current.element.querySelector(
      "hbr-table-header hbr-checkbox",
    );
    dataRef.current.selections = selections;
    // update selection state of select all check box in header
    if (columnCheckbox !== null) {
      if (selectionCount >= maxSelection) {
        columnCheckbox.checked = true;
        columnCheckbox.indeterminate = false;
      }
      else {
        columnCheckbox.checked = false;
        columnCheckbox.indeterminate = selectionCount > 0 ? true : false;
      }
    }
    // get check box elements in rows
    const hbrCheckboxes = tableRef.current.element.querySelectorAll(
      "hbr-table-data hbr-checkbox"
    );
    // update selection state
    for (const item of hbrCheckboxes) {
      if (selections.includes(item.id)) {
        if (item.checked === false) item.checked = true;
        if (item.disabled === true) item.disabled = false;
      }
      else {
        if (item.checked === true) item.checked = false;
        if (
          item.disabled === false
          && selections.length >= maxSelection
        ) item.disabled = true;
        else if (
          item.disabled === true
          && selections.length < maxSelection
        ) item.disabled = false;
      }
    }
  }
};

export default AppHOC(Table);
