import { createContext } from "@cisco-dna/redux-utils";
import {
  parseAppByDropQoe,
  parseAppByRiseUsage,
  parseQoeDistrubution,
  updateCustomList
} from "../../config/apiParsers/applications";
import { ACTIONS } from "./actions";
import { formatEndpointsBySiteid } from "../../utils/waniCommon";

const reduxContext = createContext();

export default reduxContext;

const initialState = {
  qoeByClass: [],
  appsByRise: [],
  appsByDrop: [],
  error: false,
  trendingAppsFilter: {
    // default filters for Trending Apps dashlet on Application summary
    drop: "vqoe",
    rise: "usage"
  },
  applicationList: [],
  qoeHealth: [
    {
      key: "all",
      value: "All"
    },
    {
      key: "poor",
      value: "Poor"
    },
    {
      key: "fair",
      value: "Fair"
    },
    {
      key: "good",
      value: "Good"
    },
    {
      key: "gray",
      value: "Unknown"
    }
  ],
  selectedCustomFilter: {
    qoeFilter: "all",
    classFilter: "all"
  },
  timeFilter: [],
  appClassList: [],
  applicationList_loader: true,
  qoeByClass_loader: true,
  appByRise_loader: true,
  appsByDrop_loader: true,
  appGroupSitesLoader: true,
  endpointsLoader: true,
  recoEndpointsLoader: true,
  endpointLoader: true,
  actionCardLoader: true
};

const reducer = (state = initialState, action) => {
  const { type, filter, payload, error, loading } = action;

  switch (type) {
    case ACTIONS.SET_APPLICATION_LIST_LOADING:
      return {
        ...state,
        applicationList_loader: true
      };
    case ACTIONS.SET_APPS_BY_DROP_LOADING:
      return {
        ...state,
        appsByDrop_loader: true
      };
    case ACTIONS.SET_APPS_BY_RISE_LOADING:
      return {
        ...state,
        appByRise_loader: true
      };
    case ACTIONS.SET_QOE_BY_CLASS_LOADING:
      return {
        ...state,
        qoeByClass_loader: true
      };
    case ACTIONS.SET_QOE_BY_CLASS:
      return {
        ...state,
        qoeByClass: parseQoeDistrubution(payload),
        qoeByClassError: null,
        qoeByClass_loader: false
      };
    case ACTIONS.SET_APPS_BY_RISE:
      return {
        ...state,
        appsByRise: parseAppByRiseUsage(payload, filter),
        appByRise_loader: false,
        appByRiseError: null
      };
    case ACTIONS.SET_APPS_BY_DROP:
      return {
        ...state,
        appsByDrop: parseAppByDropQoe(payload),
        appsByDrop_loader: false,
        appsByDropError: null
      };
    case ACTIONS.SET_TRENDING_APPS_FILTER: {
      if (payload.type === "DROP") {
        return {
          ...state,
          trendingAppsFilter: Object.assign({}, state.trendingAppsFilter, {
            drop: payload.filter
          })
        };
      }
      return {
        ...state,
        trendingAppsFilter: Object.assign({}, state.trendingAppsFilter, {
          rise: payload.filter
        })
      };
    }
    case ACTIONS.SET_APPLICATION_LIST:
      return {
        ...state,
        applicationList: updateCustomList(payload),
        applicationList_loader: false
      };
    case ACTIONS.SET_APP_CLASS_LIST:
      return {
        ...state,
        appClassList: payload
      };
    case ACTIONS.SET_TIMEFILTER:
      return {
        ...state,
        timeFilter: payload
      };
    case ACTIONS.SET_SELECTED_CUSTOM_FILTER:
      return {
        ...state,
        selectedCustomFilter: payload
      };
    case ACTIONS.SET_APP_GROUP_SITES:
      return {
        ...state,
        appGroupSites: payload,
        appGroupSitesLoader: false
      };
    case ACTIONS.SET_ENDPOINTS: {
      const objectEndpoint = formatEndpointsBySiteid(payload.endpoints);
      return {
        ...state,
        error: false,
        endpoints: objectEndpoint,
        endpointsLoader: loading.endpointsLoader
      };
    }
    case ACTIONS.SET_ENDPOINT:
      return {
        ...state,
        error: false,
        endpoint: payload.endpoint,
        endpointLoader: loading.endpointLoader
      };
    case ACTIONS.SET_RECO_ENDPOINTS:
      return {
        ...state,
        error: false,
        recoEndpoints: payload,
        recoEndpointsLoader: loading.recoEndpointsLoader
      };
    case ACTIONS.SET_ACTION_CARDS_LOADER:
      return {
        ...state,
        actionCardLoader: payload.actionCardLoader,
        error: false
      };
    case ACTIONS.SET_ACTION_CARDS:
      return {
        ...state,
        actionCards: payload.data,
        actionCardLoader: payload.actionCardLoader,
        error: false
      };
    case ACTIONS.RECO_CARDS_LOADER:
      return {
        ...state,
        recoEndpoints: payload
      };
    case ACTIONS.SET_ERROR: {
      return {
        ...state,
        ...loading,
        error: error
      };
    }

    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: "vanalytics.applications",
  REDUCER: reducer,
  ACTION_TYPE: [
    ACTIONS.SET_QOE_BY_CLASS,
    ACTIONS.SET_APPS_BY_RISE,
    ACTIONS.SET_APPS_BY_DROP,
    ACTIONS.SET_TRENDING_APPS_FILTER,
    ACTIONS.SET_APPLICATION_LIST,
    ACTIONS.SET_QOE_HEALTH,
    ACTIONS.SET_SELECTED_CUSTOM_FILTER,
    ACTIONS.SET_TIMEFILTER,
    ACTIONS.SET_APPLICATION_LIST_LOADING,
    ACTIONS.SET_APP_CLASS_LIST,
    ACTIONS.SET_APPS_BY_DROP_LOADING,
    ACTIONS.SET_APPS_BY_RISE_LOADING,
    ACTIONS.SET_QOE_BY_CLASS_LOADING,
    ACTIONS.SET_ERROR,
    ACTIONS.SET_CACHE,
    ACTIONS.SET_APP_GROUP_SITES,
    ACTIONS.SET_ENDPOINTS,
    ACTIONS.SET_ENDPOINT,
    ACTIONS.SET_RECO_ENDPOINTS,
    ACTIONS.SET_ACTION_CARDS,
    ACTIONS.SET_ACTION_CARDS_LOADER,
    ACTIONS.RECO_CARDS_LOADER
  ]
});
