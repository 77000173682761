import { formatNumberNDecimalPlaces, formatBytes } from "../../../utils/format";
import i18n from "amdi18n-loader!../../nls/i18n";
import { BASE_URL } from "../../../apis/apiConstants";
import { getColor } from "../../../utils/colors";
import { defaultCellValue } from "../../../utils/enums";

export const getTableColumns = (history, siteId = -1) => {
  const tableColumns = [
    {
      prop: "application",
      name: i18n.applicationTableViewName,
      sortable: true,
      size: 250,
      cellTemplate: (createElement, { model, prop }) => {
        const linkElement = createElement(
          "div",
          {
            class: "table-link",
            onClick: () => {
              history.push(`${BASE_URL}/Dashboards/${model[prop]}`);
            }
          },
          model[prop]
        );
        return createElement("div", { class: "flex-items" }, linkElement);
      }
    },
    {
      prop: "vqoe_score",
      name: i18n.applicationTableViewQoE,
      sortable: true,
      size: 100,
      cellProperties: ({ model, prop }) => {
        return { style: { color: getColor(model[prop], "qoe") } };
      }
    },
    {
      prop: "vqoe_change",
      name: i18n.applicationTableViewQoEChange,
      sortable: true,
      size: 120,
      cellProperties: ({ model, prop }) => {
        return model[prop];
      }
    },
    {
      prop: "site_count",
      name: siteId === -1
        ? i18n.applicationTableViewSiteCount
        : i18n.applicationTableViewCircuitCount,
      sortable: true,
      size: 120
    },
    {
      prop: "application_class",
      name: i18n.applicationTableViewSiteTraffic,
      size: 200,
      sortable: true,
    },
    {
      prop: "application_family_long_name",
      name: i18n.applicationTableViewAppFamily,
      size: 240,
      sortable: true,
    },
    {
      prop: "usage",
      name: i18n.applicationTableViewUsage,
      size: 120,
      sortable: true,
      order: "desc"
    },
    {
      prop: "packet_loss",
      name: i18n.applicationTableViewLoss,
      size: 140,
      sortable: true
    },
    {
      prop: "latency",
      name: i18n.applicationTableViewLatency,
      size: 120,
      sortable: true
    },
    {
      prop: "jitter",
      name: i18n.applicationTableViewJitter,
      size: 120,
      sortable: true
    }
  ];
  return tableColumns;
};

const validateAndFormatNumber = (value, decimalPlaces = 1) => {
  if (Number.isFinite(value))
    return formatNumberNDecimalPlaces(value, decimalPlaces);
  return defaultCellValue;
};

export const setApplicationsData = data => {
  for (const item of data) {
    item.checked = false;
    item.usage = formatBytes(item.usage);
    if (item.vqoe_status === "unknown") {
      item.vqoe_score = defaultCellValue;
      item.vqoe_change = defaultCellValue;
      item.packet_loss = defaultCellValue;
      item.latency = defaultCellValue;
      item.jitter = defaultCellValue;
    }
    else {
      item.vqoe_score = validateAndFormatNumber(item.vqoe_score);
      item.vqoe_change = validateAndFormatNumber(item.vqoe_change);
      if (item.vqoe_change > 0) item.vqoe_change = `+${item.vqoe_change}`;
      item.packet_loss = validateAndFormatNumber(item.packet_loss);
      item.latency = validateAndFormatNumber(item.latency);
      item.jitter = validateAndFormatNumber(item.jitter);
    }
  }
};
