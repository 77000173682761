import React, { Suspense } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PropTypes } from "prop-types";
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import _ from "lodash";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import reduxContext from "../reducer";

import css from "../applicationsMagneticStyle.less";
import * as actions from "../actions";
import Spinner from "../../../common/Spinner";
import AppHOC from "../../../generics/AppHOC";
import {
  formatString,
  formatBytes,
  formatNumberNDecimalPlaces,
  formatNumber
} from "../../../utils/format";
import { hasFilterChanged } from "../../../utils/common";
import { BASE_URL } from "../../../apis/apiConstants";

const NoDataAvailable = React.lazy(() =>
  import("../../../common/NoDataAvailable")
);
const ErrorComponent = React.lazy(() =>
  import("../../../common/ErrorComponent")
);
const TableTrendingApplications = React.lazy(() =>
  import("../../../common/TableTrendingApplications")
);

const [HbrCard, HbrSelect, HbrOption, HbrTable] = reactWrapper([
  "hbr-card",
  "hbr-select",
  "hbr-option",
  "hbr-table"
]);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({
  applications: state.vanalytics.applications
});

const APP_RISE_FILTERS = [
  { label: i18nMessageBundle.trendingAppsViewBandwidth, value: "usage" },
  { label: i18nMessageBundle.trendingAppsViewLoss, value: "loss" },
  { label: i18nMessageBundle.trendingAppsViewLatency, value: "latency" }
];

class TrendingAppsView extends React.Component {
  constructor() {
    super();
    this.state = {
      appQoEData: null,
      appUsageData: null,
      appRiseDropdownValueData: "usage",
      appdataProcessed: false
    };
  }

  getData = () => {
    this.setState({
      appQoEData: getFilteredData(this.props.globalFilter, "vqoe"),
      appUsageData: getFilteredData(this.props.globalFilter, "usage"),
      appRiseDropdownValueData: "usage",
      appdataProcessed: true
    });
    this.props.actions.setTrendingAppsFilter("RISE", "usage");
  };

  componentDidMount() {
    if (
      this?.props?.globalFilter?.app_data_with_family_sla != undefined &&
      this?.props?.globalFilter?.app_data_with_family_sla &&
      this?.props?.globalFilter?.app_data_with_family_sla.length > 0 &&
      !this.state.appdataProcessed
    ) {
      this.getData();
    }
  }

  componentDidUpdate(prevProps) {
    if (hasFilterChanged(this.props.globalFilter, prevProps.globalFilter)) {
      this.setState({
        appdataProcessed: false
      });
      this.props.actions.setTrendingAppsFilter("RISE", "usage");
    }
    if (
      !_.isEqual(
        this.props.globalFilter.app_data_with_family_sla,
        prevProps.globalFilter.app_data_with_family_sla
      )
    ) {
      if (
        this?.props?.globalFilter?.app_data_with_family_sla != undefined &&
        this?.props?.globalFilter?.app_data_with_family_sla &&
        this?.props?.globalFilter?.app_data_with_family_sla.length > 0 &&
        !this.state.appdataProcessed
      ) {
        this.getData();
      } else if (
        this?.props?.globalFilter?.app_data_with_family_sla != undefined &&
        this?.props?.globalFilter?.app_data_with_family_sla &&
        this?.props?.globalFilter?.app_data_with_family_sla.length == 0 &&
        !this.state.appdataProcessed
      ) {
        this.setState({
          appQoEData: [],
          appUsageData: [],
          appRiseDropdownValueData: "usage",
          appdataProcessed: true
        });
      }
    }
  }

  onChangeTrend(evt) {
    const type = "RISE";
    const filter = evt;
    const { setTrendingAppsFilter } = this.props.actions;
    setTrendingAppsFilter(type, filter);
    // reload dashlet data
    if (type === "RISE") {
      this.setState({
        appUsageData: getFilteredData(this.props.globalFilter, filter),
        appRiseDropdownValueData: filter
      });
    }
  }

  getTableData = () => {
    const { trendingAppsFilter } = this.props.applications;
    const appData = this.state.appUsageData.data.map(item => {
      if (trendingAppsFilter.rise === "usage") {
        item.previousTableData = formatBytes(item.previous);
        item.currentTableData = formatBytes(item.current);
        if (Math.sign(item.change) < 0) {
          item.changeTableData = `-${formatBytes(Math.abs(item.change))}`;
        } else {
          item.changeTableData = formatBytes(Math.abs(item.change));
        }
      } else {
        item.previousTableData = formatNumberNDecimalPlaces(item.previous);
        item.currentTableData = formatNumberNDecimalPlaces(item.current);
        if (trendingAppsFilter.rise === "loss")
          item.changeTableData = formatNumber(item.change, "changePercent", 1);
        else if (trendingAppsFilter.rise === "latency")
          item.changeTableData =
            formatNumber(item.change, "default", 1) + " ms";
      }
      item.applicationTableData = formatString(item.application, 16);
      return item;
    });
    return appData;
  };

  render() {
    const getApplicationDetails = application => {
      const path = `${BASE_URL}/Dashboards/${application}`;
      this.props.history.push(path);
    };

    const columns = [
      {
        prop: "applicationTableData",
        name: "Applications",
        size: 130,
        readonly: true,
        cellProperties: () => {
          return {
            class: {
              "hbr-table-cell-readonly-background-color": true
            }
          };
        },
        cellTemplate: (createElement, props) => {
          const content = createElement("div", {
            innerHTML: `
                  <hbr-link class="application-trending-apps-qoe-widget-link" id="app-trend-rise-app-qoe-${props.model.application}">${props.model.applicationTableData}</hbr-link>
                  <hbr-portal>
                    <hbr-tooltip anchor="#app-trend-rise-app-qoe-${props.model.application}">
                      <div slot="content">
                          <strong>${props.model.application}</strong>  
                      </div>
                    </hbr-tooltip>
                  </hbr-portal>
                `,
            onClick: e => {
              if (e && e.target) {
                getApplicationDetails(props.model.application);
              }
            }
          });
          return content;
        }
      },
      {
        prop: "previousTableData",
        name: "Previous",
        size: 80,
        readonly: true,
        cellProperties: () => {
          return {
            class: {
              "hbr-table-cell-readonly-background-color": true
            }
          };
        }
      },
      {
        prop: "currentTableData",
        name: "Current",
        size: 70,
        readonly: true,
        cellProperties: () => {
          return {
            class: {
              "hbr-table-cell-readonly-background-color": true
            }
          };
        }
      },
      {
        prop: "changeTableData",
        name: "Change",
        size: 70,
        readonly: true,
        cellProperties: () => {
          return {
            class: {
              "hbr-table-cell-readonly-background-color": true
            }
          };
        }
      }
    ];

    let finaldata;
    if (
      this.state.appUsageData &&
      this.state.appUsageData.data &&
      this.state.appUsageData.data.length > 0
    ) {
      finaldata = this.getTableData();
    }
    const source = finaldata;

    const { timeFilter } = this.props.globalFilter;
    const loader =
      this?.props?.globalFilter?.app_data_with_family_sla == undefined;
    const errorTrendingApplications =
      this?.props?.globalFilter?.applicationAggregateData &&
      this?.props?.globalFilter?.applicationAggregateData.resObj &&
      this?.props?.globalFilter?.applicationAggregateData.resObj.message !=
      "successful";
    return (
      <div className={css["trending-apps"]} data-cy="trendingAppsView">
        <div className="trend-application-drop-qoe">
          <HbrCard id="non-interactive-most-drop" className="trendingAppCard">
            <div
              className={
                this.props.reporting
                  ? "trending-applications-tables--drop"
                  : "trending-applications-ui-drop"
              }
              data-cy="droppingApps"
            >
              <div className="hbr-type-h2 trendingTitle">{"Most avg. drop of QoE"}</div>

              {loader === true ? (
                <div className="home-spinner-25" data-cy="spinner-componentAppsByDropView">
                  <Spinner />
                </div>
              ) : (
                <div>
                  {this.state.appQoEData &&
                    this.state.appQoEData.data &&
                    this.state.appQoEData.data.length &&
                    loader === false ? (
                    <Suspense fallback={<Spinner />}>
                      <TableTrendingApplications
                        {...this.props}
                        applications={this.state.appQoEData.data}
                        timeFilter={timeFilter}
                      />
                    </Suspense>
                  ) : errorTrendingApplications ? (
                    <Suspense fallback={<Spinner />}>
                      <ErrorComponent
                        width={"110px"}
                        className={"super-small-dashlet-error"}
                        errorCode={
                          this.props.globalFilter.applicationAggregateData
                            .resObj.errorCode
                        }
                        message={
                          this.props.globalFilter.applicationAggregateData
                            .resObj.message
                        }
                      />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<Spinner />}>
                      <NoDataAvailable
                        text={i18nMessageBundle.applicationDashboardNoData}
                      />
                    </Suspense>
                  )}
                </div>
              )}
            </div>
          </HbrCard>
        </div>

        {/*  most avg rise of trending widget section   */}
        <div className="trend-application-rise-qoe">
          <HbrCard id="non-interactive" slot="label" className="trendTableCard">
            <div
              className={
                this.props.reporting
                  ? "trending-applications-tables--rise"
                  : "trending-applications-ui-rise"
              }
              data-cy="risingApps"
            >
              <div>
                <div className="hbr-type-h2 trendingTitle">
                  {"Most avg. rise of"}
                </div>
                <HbrSelect
                  value={this.state.appRiseDropdownValueData}
                  onHbr-change={event => {
                    this.onChangeTrend(event.currentTarget.value);
                  }}
                  name="trendDropDown"
                  size="small"
                  className="trendDropDown"
                >
                  {APP_RISE_FILTERS.map(item => (
                    <HbrOption key={item.value} value={item.value}>
                      {item.label}
                    </HbrOption>
                  ))}
                </HbrSelect>
              </div>
              {loader === true ? (
                <div className="home-spinner-25" data-cy="spinner-componentAppsByRiseView">
                  <Spinner />
                </div>
              ) : (
                <div className="usage-trend">
                  {this.state.appUsageData &&
                    this.state.appUsageData.data &&
                    this.state.appUsageData.data.length &&
                    loader === false ? (
                    <HbrTable
                      className="trendTableRise"
                      readonly
                      canFocus={false}
                      columns={columns}
                      source={source}
                    />
                  ) : errorTrendingApplications ? (
                    <Suspense fallback={<Spinner />}>
                      <ErrorComponent
                        width={"110px"}
                        className={"super-small-dashlet-error"}
                        errorCode={
                          this.props.globalFilter.applicationAggregateData
                            .resObj.errorCode
                        }
                        message={
                          this.props.globalFilter.applicationAggregateData
                            .resObj.message
                        }
                      />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<Spinner />}>
                      <NoDataAvailable
                        text={i18nMessageBundle.applicationDashboardNoData}
                      />
                    </Suspense>
                  )}
                </div>
              )}
            </div>
          </HbrCard>
        </div>
      </div>
    );
  }
}

TrendingAppsView.propTypes = {
  actions: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,
  applications: PropTypes.object.isRequired,
  reporting: PropTypes.bool,
  history: PropTypes.object
};
TrendingAppsView.defaultProps = {
  reporting: false
};

const resPropNames = {
  usage: ["usage", "prev_usage", "usage_change", "usage_change_output"],
  loss:
    ["packet_loss", "prev_packet_loss", "loss_change", "loss_change_output"],
  latency:
    ["latency", "prev_latency", "latency_change", "latency_change_output"],
  vqoe:
    ["vqoe_score", "prev_vqoe_score", "vqoe_change", "vqoe_change_output"]
};

const getFilteredData = (gFilter, filter) => {
  const resData = gFilter.app_data_with_family_sla;
  const result = { data: [] };
  if (Array.isArray(resData) && resData.length > 0) {
    const propNames = resPropNames[filter];
    const sortField = propNames[3];
    const data = resData.filter(item => item.vqoe_status !== "unknown");
    if (filter === "vqoe") data
      .sort((item1, item2) => item1[sortField] < item2[sortField] ? -1 : 1);
    else data
      .sort((item1, item2) => item1[sortField] > item2[sortField] ? -1 : 1);
    result.data = data.slice(0, 5).map(item => ({
      application: item.application,
      current: item[propNames[0]],
      previous: item[propNames[1]],
      change: item[propNames[2]],
      change_percent: item[propNames[3]]
    }));
    if (filter === "loss") result.data.sort((a, b) => b.change - a.change);
  }
  return result;
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(TrendingAppsView))
);
