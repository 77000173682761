import React from "react";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import css from "../../applicationsMagneticStyle.less";

const VqoeLegend = () => {
  const getLegendLogo = (color, label, labelValue, padding) => (
    <div
      className="vqoe-legend"
      style={{ padding: `${padding}` }}
      data-cy={`${label}`}
    >
      {label === "Unknown" ?
        <span style={{ color: color[1] }} className="legend-span"></span> :
        <>
          <span style={{ color: color[0] }} className="legend-span"></span>
          <span style={{ color: color[1] }} className="legend-span"></span>
          <span style={{ color: color[2] }} className="legend-span"></span>
        </>}
      <span className="label-legend hbr-type-body3">{label}</span>
      <span className="label-legend hbr-type-body4">{labelValue}</span>
    </div>
  );

  return (
    <div className={css["chart-legend"]} data-cy="chart-legend-map-tree">
      {getLegendLogo(
        [
          "var(--hbr-color-success-text-disabled, #c7edb4)",
          "var(--hbr-color-success-icon-weak-selected, #6dc242)",
          "var(--hbr-color-success-border, #45991f)"
        ],
        i18nMessageBundle.appExperienceGoodLabel,
        i18nMessageBundle.appExperienceGoodValue,
        "5px 0px"
      )}
      {getLegendLogo(
        [
          "var(--hbr-color-warning-highlight, #fcf5d4)",
          "var(--hbr-color-warning-medium, #e5d067)",
          "var(--hbr-color-warning-border, #cc8604)"
        ],
        i18nMessageBundle.appExperienceFairLabel,
        i18nMessageBundle.appExperienceFairValue,
        "5px 10px"
      )}
      {getLegendLogo(
        [
          "var(--hbr-color-danger-disabled, #f0b3aa)",
          "var(--hbr-color-danger-weak, #f08878)",
          "var(--hbr-color-danger, #CC2D37)"
        ],
        i18nMessageBundle.appExperiencePoorLabel,
        i18nMessageBundle.appExperiencePoorValue,
        "5px 0px"
      )}
      {getLegendLogo(
        [
          "var(--hbr-color-neutral-border, #889099)",
          "var(--hbr-color-neutral-border, #889099)",
          "var(--hbr-color-neutral-border, #889099)"
        ],
        i18nMessageBundle.appExperienceUnknownLabel,
        i18nMessageBundle.appExperienceUnknownValue,
        "5px 0px"
      )}
    </div>
  );
};
export default VqoeLegend;
