import i18n from "amdi18n-loader!../../../../nls/i18n";
import { statusColors } from "../../../../../utils/colors";

export const getQoEconfig = (qoeValues) => {
    const { vqoe, totalAppCount } = qoeValues;

    const data = [
        {
            id: "good",
            label: i18n.applicationOverviewLabelGood,
            value: vqoe["good"],
            color: statusColors.good
        },
        {
            id: "fair",
            label: i18n.applicationOverviewLabelFair,
            value: vqoe["fair"],
            color: statusColors.fair
        },
        {
            id: "poor",
            label: i18n.applicationOverviewLabelPoor,
            value: vqoe["poor"],
            color: statusColors.poor
        },
        {
            id: "unknown",
            label: i18n.applicationOverviewLabelNotAvailable,
            value: vqoe["unknown"],
            color: statusColors.gray
        }
    ];

    return {
        id: "label",
        innerRadius: 0.7,
        enableArcLinkLabels: false,
        width: 180,
        height: 180,
        margin: { top: 10, bottom: 32, left: 0, right: 0 },
        data: data,
        colors: ({ data }) => data.color,
        legends: [],
        centerContent: () => {
            return `
        <div style="display: flex; height: 100%; flex-direction: column; justify-content: center;align-items: center;">
          <div style="font-size: 24px; font-weight: 400">${totalAppCount}</div>
          <div>${i18n.appExperienceApplications}</div>
        </div>`;
        }

    }
}

