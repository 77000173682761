import i18n from "amdi18n-loader!../../../../nls/i18n";
import { statusColors } from "../../../../../utils/colors";
import { formatBytes } from "../../../../../utils/format";

export const getUsageConfig = (usage, donutInnerValues) => {
    const data = [
        {
            id: "good",
            label: i18n.applicationOverviewLabelGood,
            value: usage["good"],
            color: statusColors.good,
            valueFormat: value => formatBytes(value),
        },
        {
            id: "fair",
            label: i18n.applicationOverviewLabelFair,
            value: usage["fair"],
            color: statusColors.fair,
            valueFormat: value => formatBytes(value),

        },
        {
            id: "poor",
            label: i18n.applicationOverviewLabelPoor,
            value: usage["poor"],
            color: statusColors.poor,
            valueFormat: value => formatBytes(value),

        },
        {
            id: "unknown",
            label: i18n.applicationOverviewLabelNotAvailable,
            value: usage["unknown"],
            color: statusColors.gray,
            valueFormat: value => formatBytes(value),
        }
    ];
    const [value, title] = formatBytes(donutInnerValues).split(" ");

    return {
        id: "label",
        innerRadius: 0.7,
        enableArcLinkLabels: false,
        width: 180,
        height: 180,
        margin: { top: 10, bottom: 32, left: 0, right: 0 },
        valueFormat: value => formatBytes(value),
        data: data,
        colors: ({ data }) => data.color,
        legends: [],
        centerContent: () => {
            return `
        <div style="display: flex; height: 100%; flex-direction: column; justify-content: center;align-items: center;">
          <div style="font-size: 24px; font-weight: 400">${value}</div>
          <div>${title}</div>
        </div>`;
        }
    }
}

export const getUsageNQoEMetrics = (applicationsList, totalUsage) => {
    let usage_summary_data = [
        { usage: 0, usage_percentage: 0, vqoe: "good" },
        { usage: 0, usage_percentage: 0, vqoe: "fair" },
        { usage: 0, usage_percentage: 0, vqoe: "poor" },
        { usage: 0, usage_percentage: 0, vqoe: "unknown" }
    ];
    let vqoe_summary_data = [
        { app_count: 0, vqoe: "good", vqoe_percentage: 0 },
        { app_count: 0, vqoe: "fair", vqoe_percentage: 0 },
        { app_count: 0, vqoe: "poor", vqoe_percentage: 0 },
        { app_count: 0, vqoe: "unknown", vqoe_percentage: 0 }
    ];

    if (applicationsList && applicationsList.length > 0) {
        const qoeList = ["good", "fair", "poor", "unknown"];
        const appsList = Object.groupBy(applicationsList, ({ vqoe_status }) => vqoe_status);
        let vqoeListOfObject = { "good": [], "fair": [], "poor": [], "unknown": [] }

        vqoeListOfObject = Object.assign(vqoeListOfObject, appsList);

        qoeList.forEach((vqoe, index) => {
            const appcount = vqoeListOfObject[vqoe].length
            vqoe_summary_data[index]["app_count"] = appcount;
            vqoe_summary_data[index]["vqoe_percentage"] = (appcount / applicationsList.length) * 100;
        })


        qoeList.forEach((vqoe, index) => {
            let usage = 0;
            vqoeListOfObject[vqoe].forEach((vqoeObject) => {
                usage += vqoeObject.usage;
            })

            usage_summary_data[index]["vqoe_percentage"] = (usage / totalUsage) * 100;
            usage_summary_data[index]["usage"] = usage;
        })

        usage_summary_data[3]["vqoe"] = "gray";
        vqoe_summary_data[3]["vqoe"] = "gray";

        return { usage_summary: usage_summary_data, vqoe_summary: vqoe_summary_data };
    }
    return { usage_summary: {}, vqoe_summary: {} }
}
