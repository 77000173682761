import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";

import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import { am4core, am4charts } from "../../../../loaders/amchartsLoader";
import { getTreeColor } from "../../../../utils/views/applications";
import { formatBytes } from "../../../../utils/format";
import { colors } from "../../../../utils/colors";
import { BASE_URL } from "../../../../apis/apiConstants";

const TreeMap = ({ data, selectedSite }) => {
  const chartRef = useRef(null);
  const CHART_ID = "isLoaded_chart";
  const history = useHistory();

  useEffect(() => {
    if (!chartRef.current) {
      /* Create chart */
      am4core.options.autoDispose = true;
      chartRef.current = am4core.create(CHART_ID, am4charts.TreeMap);

      const treeMapData = [
        {
          name: "Good",
          children: []
        },
        {
          name: "Fair",
          children: []
        },
        {
          name: "Poor",
          children: []
        },
        {
          name: "Unknown (No QoE)",
          children: []
        }
      ];

      data.map(d => {
        const obj = createTreeMapObject(d, selectedSite);
        treeMapData[0].children.push(obj);
      });

      /* Show color based on fair, poor and good */
      chartRef.current.data = treeMapData;

      /* Set color step */
      chartRef.current.colors.step = 2;

      /* Define data fields */
      chartRef.current.dataFields.value = "usage";
      chartRef.current.dataFields.name = "name";
      chartRef.current.dataFields.color = "color";
      chartRef.current.dataFields.children = "children";
      chartRef.current.layoutAlgorithm = chartRef.current.binaryTree;
      chartRef.current.zoomable = false;
      chartRef.current.numberFormatter.numberFormat = "#.#";

      const topContainer = chartRef.current.chartContainer.createChild(
        am4core.Container
      );
      topContainer.toBack();
      topContainer.paddingBottom = 15;
      topContainer.width = am4core.percent(100);

      const dateTitle = topContainer.createChild(am4core.Label);
      dateTitle.userClassName = "app-experince-usage";
      dateTitle.marginTop = "-8px";
      dateTitle.text = `${i18nMessageBundle.appExperienceByUsage}`;
      dateTitle.align = "left";
      dateTitle.fontWeight = 500;
      dateTitle.fontSize = 16;
      dateTitle.fontFamily = "Inter";
      dateTitle.color = colors.grayStrongBase;

      /* level 1 series template */
      const level1SeriesTemplate = chartRef.current.seriesTemplates.create("1");
      level1SeriesTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;

      level1SeriesTemplate.tooltip.getFillFromObject = false;
      level1SeriesTemplate.tooltip.autoTextColor = false;
      level1SeriesTemplate.tooltip.animationDuration = 0;
      level1SeriesTemplate.tooltip.pointerOrientation = "right";
      level1SeriesTemplate.strokeOpacity = 1;

      level1SeriesTemplate.tooltip.background.fill = am4core.color(
        colors.gray100
      );
      level1SeriesTemplate.tooltip.label.fill = am4core.color(colors.gray40);
      // set tooltip border style
      level1SeriesTemplate.tooltip.background.stroke = am4core.color(
        colors.gray45
      );
      level1SeriesTemplate.tooltip.background.strokeWidth = 2;
      // remove tooltip pointer
      // set tooltip shadow style
      const shadow = level1SeriesTemplate.tooltip.background.filters.getIndex(
        0
      );
      shadow.dy = 4;
      shadow.blur = 12;
      shadow.color = am4core.color(colors.gray0);
      shadow.opacity = 0.18;

      const level1ColumnTemplate = level1SeriesTemplate.columns.template;
      const htmlTemplate = getHtmlTemplate();
      level1ColumnTemplate.tooltipHTML = htmlTemplate;
      level1ColumnTemplate.column.fillOpacity = 1;
      level1ColumnTemplate.column.strokeWidth = 4;
      level1ColumnTemplate.column.stroke = am4core.color(colors.gray100);

      /* Add labels */
      const bullet1 = level1SeriesTemplate.bullets.push(
        new am4charts.LabelBullet()
      );
      bullet1.locationY = 0.5;
      bullet1.locationX = 0.5;
      bullet1.label.text =
        "[font-size:14px;font-weight:600;]{usagePercentage}% [font-size:12px;font-weight:400;]{name}";
      bullet1.label.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      bullet1.fontWeight = 300;
      bullet1.label.fill = am4core.color(colors.gray15);
      bullet1.fill = am4core.color(colors.gray100);
      bullet1.tooltipHTML = htmlTemplate;

      bullet1.events.on("hit", ev => {
        const { name } = ev.target.dataItem.dataContext;
        const path = `${BASE_URL}/Dashboards/${name}`;
        history.push(path);
      });
      /** Add event to check the height and width of the map shapes */
      /*** when browser is resized ,application name in the tree map is hide .so we commented this*/
      /* level1SeriesTemplate.columns.template.events.on("sizechanged", ev => {
        if (ev.target.dataItem && ev.target.dataItem.bullets) {
          const width = ev.target.pixelWidth;
          const height = ev.target.pixelHeight;

          ev.target.dataItem.bullets.each((id, bullet) => {
            if (height > 60 && width > 60) {
              bullet.show();
            } else {
              bullet.hide();
            }
          });
        }
      }); */

      /** Add event to tigger redirect to page 360 */
      level1SeriesTemplate.columns.template.interactionsEnabled = true;
      level1SeriesTemplate.columns.template.events.on("hit", ev => {
        const { name } = ev.target.dataItem.dataContext;
        const path = `${BASE_URL}/Dashboards/${name}`;
        history.push(path);
      });
      chartRef.current.maxLevels = 2;
      return () => {
        if (chartRef.current) chartRef.current.dispose();
      };
    }
    return () => {
      if (chartRef.current) {
        setTimeout(
          ref => {
            ref.dispose();
            chartRef.current.events.disable();
          },
          100,
          chartRef.current
        );
      }
    };
  }, []);

  const createTreeMapObject = (d, selectedSite) => {
    let tips = "";

    if (d.application.toLowerCase() === "unknown") {
      if (d.application_family_long_name.toLowerCase() == "unknown") {
        tips = `${d.application} (${d.application_class})`;
      } else {
        tips = `${d.application} (${d.application_family_long_name})`;
      }
    } else {
      tips = d.application;
    }
    return {
      name: d.application,
      siteText: selectedSite == -1 ? i18nMessageBundle.appExperienceSite : i18nMessageBundle.appExperienceCircuit,
      tooltip: tips,
      color: getTreeColor(d.vqoe_score),
      usage: d.usage,
      usageConverted: formatBytes(d.usage),
      usagePercentage: d.usage_percentage < 1 ? "<1" : d.usage_percentage,
      packetLoss: d.packet_loss,
      packetLossUnits: d.packet_loss == "-" ? "" : " %",
      jitter: d.jitter,
      jitterUnits: d.jitter == "-" ? "" : " ms",
      latency: d.latency,
      latencyUnits: d.latency == "-" ? "" : " ms",
      vqoeScore: d.vqoe_score,
      vqoeScoreText:
        d.vqoe_score == "-" ? "" : i18nMessageBundle.appExperienceOutOf10,
      totalSites: d.usage_site_count,
      fairCount: d.fair_count,
      goodCount: d.good_count,
      badCount: d.bad_count ? d.bad_count : 0,
      NoQoECount: d.gray_count ? d.gray_count : 0
    };
  };

  const getHtmlTemplate = () => `
      <div>
        <table>
          <tr>
            <td class="tooltip-header-tree-map">{tooltip}</td>
            <td></td>
          </tr>
          <tr class="tooltip-tree-map-first-row">
            <td>${i18nMessageBundle.appExperienceQoE}</td>
            <td><b><span style="color:{color};">{vqoeScore}</span></b>{vqoeScoreText}</td>
          </tr>
          <tr class="tooltip-tree-map">
            <td>{siteText}</td>
            <td>
              {totalSites}
              ({goodCount} ${i18nMessageBundle.appExperienceGood},
              {fairCount} ${i18nMessageBundle.appExperienceFair},
              {badCount} ${i18nMessageBundle.appExperiencePoor},
              {NoQoECount} ${i18nMessageBundle.appExperienceGray})
            </td>
          </tr>
          <tr class="tooltip-tree-map">
            <td>${i18nMessageBundle.appExperienceUsage}</td>
            <td>{usageConverted} ({usagePercentage}% ${i18nMessageBundle.appExperienceOut})</td>
          </tr>
          <tr class="tooltip-tree-map">
            <td>${i18nMessageBundle.appExperiencePacket}</td>
            <td>{packetLoss}{packetLossUnits}</td>
          </tr>
          <tr class="tooltip-tree-map">
            <td>${i18nMessageBundle.appExperienceLatency}</td>
            <td>{latency}{latencyUnits} </td>
          </tr>
          <tr class="tooltip-tree-map">
            <td>${i18nMessageBundle.appExperienceJitter}</td>
            <td>{jitter}{jitterUnits}</td>
          </tr>
        </table>
      </div>`;
  return <div id={CHART_ID} className="app-tree-map" />;
};

TreeMap.propTypes = {
  data: PropTypes.array.isRequired,
  selectedSite: PropTypes.number.isRequired
};

export default TreeMap;
