import axios from "axios";
import ApiService from "../../config/api-config";
import * as apiConstant from "../../apis/apiConstants";
import { recommandActionCards } from "../../utils/waniCommon";

export const ACTIONS = {
  SET_TRENDING_APPS_FILTER: "SET_TRENDING_APPS_FILTER",
  SET_APPLICATION_LIST_LOADING: "SET_APPLICATION_LIST_LOADING",
  SET_APPS_BY_DROP_LOADING: "SET_APPS_BY_DROP_LOADING",
  SET_APPS_BY_RISE_LOADING: "SET_APPS_BY_RISE_LOADING",
  SET_QOE_BY_CLASS_LOADING: "SET_QOE_BY_CLASS_LOADING",
  SET_QOE_BY_CLASS: "SET_QOE_BY_CLASS",
  SET_APPS_BY_RISE: "SET_APPS_BY_RISE",
  SET_APPS_BY_DROP: "SET_APPS_BY_DROP",
  SET_TIMEFILTER: "SET_TIMEFILTER",
  SET_APPLICATION_LIST: "SET_APPLICATION_LIST",
  SET_APP_CLASS_LIST: "SET_APP_CLASS_LIST",
  SET_SELECTED_CUSTOM_FILTER: "SET_SELECTED_CUSTOM_FILTER",
  SET_QOE_HEALTH: "SET_QOE_HEALTH",
  SET_ERROR: "SET_ERROR",
  SET_CACHE: "SET_CACHE",
  SET_APP_GROUP_SITES: "SET_APP_GROUP_SITES",
  SET_ENDPOINTS: "SET_ENDPOINTS",
  SET_ENDPOINT: "SET_ENDPOINT",
  RECO_ENDPOINTS: "RECO_ENDPOINTS",
  SET_RECO_ENDPOINTS: "SET_RECO_ENDPOINTS",
  SET_ACTION_CARDS: "SET_ACTION_CARDS",
  SET_ACTION_CARDS_LOADER: "SET_ACTION_CARDS_LOADER",
  RECO_CARDS_LOADER: "RECO_CARDS_LOADER"
};

export const setTrendingAppsFilter = (type, filter) => dispatch => {
  return dispatch({
    type: ACTIONS.SET_TRENDING_APPS_FILTER,
    payload: {
      type,
      filter
    }
  });
};

export const getQoEDetailsForAppExperienceView = timeFilter => {
  let baseUrl = apiConstant.QOE_USAGE_DETAILS_URL;
  return axios.request({
    method: "POST",
    url: baseUrl,
    data: timeFilter
  });
};

export const getUsageDetailsForAppExperienceView = timeFilter => {
  let baseUrl = apiConstant.TABLE_CUSTOMLIST_URL;
  return axios.request({
    method: "POST",
    url: baseUrl,
    data: timeFilter
  });
};
export const appClassList = (type, filter) => dispatch => {
  const appClassList = require(`../../config/sidebar/TrendAnalysis/CustomListTable/${type}`);

  dispatch({
    type: `SET_${type}`,
    filter,
    payload: appClassList
  });
};
export const qoeDistrubution = (type, filter) => dispatch => {
  startLoader(type, dispatch);
  ApiService.getqoeDistrubution(filter).then(res => {
    if (!res.errorObject)
      dispatch({
        type: `SET_${type}`,
        payload: res.data.data
      });
    else
      dispatch({
        type: ACTIONS.SET_ERROR,
        loading: {
          qoeByClass: [],
          qoeByClass_loader: false,
          qoeByClassError: res.errorObject
        }
      });
  });
};

export const trendApplicationByRiseUsage = (type, filter) => dispatch => {
  startLoader(type, dispatch);
  ApiService.getTrendApplicationByRiseOrDrop(filter).then(res => {
    if (!res.errorObject)
      dispatch({
        type: `SET_${type}`,
        filter: filter.api_key,
        payload: res.data.data
      });
    else
      dispatch({
        type: ACTIONS.SET_ERROR,
        loading: {
          appsByRise: [],
          appByRise_loader: false,
          appByRiseError: res.errorObject
        }
      });
  });
};
export const trendApplicationByDropQoe = (type, filter) => dispatch => {
  startLoader(type, dispatch);
  ApiService.getTrendApplicationByRiseOrDrop(filter).then(res => {
    if (!res.errorObject)
      dispatch({
        type: `SET_${type}`,
        payload: res.data.data
      });
    else
      dispatch({
        type: ACTIONS.SET_ERROR,
        loading: {
          appsByDrop: [],
          appsByDrop_loader: false,
          appsByDropError: res.errorObject
        }
      });
  });
};
export const setSelectedTimeFilter = (type, filter) => dispatch => {
  dispatch({
    type: `SET_${type}`,
    filter,
    payload: filter.timeFilter
  });
};
export const selectedCustomFilter = (type, filter) => dispatch => {
  dispatch({
    type: `SET_${type}`,
    filter,
    payload: filter.customFilters
  });
};
const startLoader = (type, dispatch) => {
  dispatch({
    type: `SET_${type}_LOADING`
  });
};

export const getAltoEndpoints = (type, query, overlayId) => dispatch => {
  ApiService.getAltoEndpoints(query, overlayId)
    .then(res => {
      const payload =
        res.data.endpoints.length === 1
          ? {
              payload: {
                endpoint: res.data.endpoints
              },
              loading: {
                endpointLoader: false
              }
            }
          : {
              payload: {
                endpoints: res.data.endpoints
              },
              loading: {
                endpointsLoader: false
              }
            };
      dispatch({
        type: `SET_${type}`,
        ...payload
      });
    })
    .catch(() => {
      dispatch({
        type: ACTIONS.SET_ERROR,
        loading: { endpointsLoader: false, endpointLoader: false },
        error: true
      });
    });
};

export const getActionCardsPerSite = (
  type,
  endpoint,
  appClass,
  selectedOverlay
) => dispatch => {
  dispatch({
    type: ACTIONS.SET_ACTION_CARDS_LOADER,
    payload: {
      actionCardLoader: true,
      error: false
    }
  });

  let pathQuery = {
    windowSize: "30d",
    aggregatedBy: "actionId",
    appClass: appClass,
    numWindows: 1,
    experimental: "v2",
    limit: 5000
  };
  const actionCardsPromise = [
    ApiService.getAltoPaths(pathQuery, selectedOverlay),
    ApiService.getAltoEndpointRecommendationAction(
      {
        appClass: appClass,
        limit: 5000
      },
      selectedOverlay
    )
  ];

  Promise.all([...actionCardsPromise]).then(actionResults => {
    let actionPathsAndStates = [];
    let actionRecommendations = [];
    let errorObject;

    for (const actionResult of actionResults) {
      if (actionResult.errorObject) {
        errorObject = actionResult.errorObject;
        break;
      } else {
        if (actionResult.data.actions) {
          actionPathsAndStates = actionResult.data.actions;
        } else {
          actionRecommendations = actionResult.data.recoPathMetrics;
        }
      }
    }

    if (errorObject) {
      dispatch({
        type: ACTIONS.SET_ERROR,
        error: errorObject,
        payload: {
          actionCardLoader: false
        }
      });
    } else {
      const actionResult = recommandActionCards(
        actionPathsAndStates,
        endpoint,
        actionRecommendations
      );
      dispatch({
        type: `SET_${type}`,
        error: false,
        payload: {
          data: actionResult,
          error: false,
          actionCardLoader: false
        }
      });
    }
  });
};
