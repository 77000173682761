import React, { Suspense, useEffect, useState } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";
import format from "string-template";

//components
import { DnxBar } from "../../../loaders/DNXLoader";
import { statusColors } from "../../../utils/colors";
import ChartLegend from "../../../common/ChartLegend";
import Spinner from "../../../common/Spinner";
import AppHOC from "../../../generics/AppHOC";

//reducer files
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import reduxContext from "../reducer";
import * as actions from "../actions";

//styles
import css from "../applicationsMagneticStyle.less";

//utils
import { getBarChartballoonFunctionContent } from "../../../utils/views/applications";
import { parseQoeDistrubution } from "../../../config/apiParsers/applications";

//i18n file
import i18n from "amdi18n-loader!../../nls/i18n";

const NoDataAvailable = React.lazy(() =>
  import("../../../common/NoDataAvailable")
);
const ErrorComponent = React.lazy(() =>
  import("../../../common/ErrorComponent")
);

const [HbrCard, HbrTooltip, HbrIcon] = reactWrapper([
  "hbr-card",
  "hbr-tooltip",
  "hbr-icon"
]);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const QoeDistributionView = (props) => {
  const { globalFilter } = props;
  const [appClassData, setAppClassData] = useState();
  const [maxPoorApps, setMaxPoorApps] = useState();
  const [appClassDataPrev, setAppClassDataPrev] = useState(false);
  const loader = globalFilter.app_data_with_family_sla == undefined;
  const errorAppClass = globalFilter?.applicationAggregateData &&
    globalFilter?.applicationAggregateData.resObj &&
    globalFilter?.applicationAggregateData.resObj.message !=
    "successful";
  const names = [
    "Good (QoE 8 - 10)",
    "Fair (QoE 5 - <8)",
    "Poor (QoE 0 - <5)",
    "Unknown (No QoE)",
  ];

  useEffect(() => {
    setAppClassDataPrev(false)

    if (globalFilter?.app_data_with_family_sla) {
      if (
        globalFilter?.app_data_with_family_sla?.length > 0 &&
        !appClassDataPrev
      ) {
        getData();
      } else if (
        globalFilter.app_data_with_family_sla &&
        globalFilter.app_data_with_family_sla.length == 0 &&
        !appClassDataPrev
      ) {
        setMaxPoorApps([]);
        setAppClassDataPrev(true);
        setAppClassData([])
      }
    }

  }, [
    globalFilter.selectedSite,
    globalFilter.timePeriodSelected,
    globalFilter.app_data_with_family_sla])



  const getData = () => {
    setAppClassData(null);
    setAppClassDataPrev(true);
    let qoeStatusData = [];
    const resData = globalFilter.app_data_with_family_sla;
    if (Array.isArray(resData) && resData.length > 0) {
      const appClassData = {};
      for (const { application_class, vqoe_status } of resData) {
        let item = appClassData[application_class];
        if (item instanceof Object === false)
          item = appClassData[application_class] =
            { application_class, bad: 0, fair: 0, good: 0, unknown: 0 };
        vqoe_status === "poor" ? item.bad += 1 : item[vqoe_status] += 1;
      }
      qoeStatusData = Object.values(appClassData);
    }

    const appClassDataParser = parseQoeDistrubution(qoeStatusData);
    if (props.reporting) {
      const updatedMaxPoorApps = getPoorestApplicationClass(qoeStatusData);
      setMaxPoorApps(updatedMaxPoorApps);
    }
    setAppClassData(appClassDataParser)
  };

  const getPoorestApplicationClass = (qoeByClass) => {
    if (!qoeByClass.length) return null;
    let maxBad = -1;
    const poorApplicationClasses = qoeByClass.filter(({ bad }) => bad > 0);
    poorApplicationClasses.forEach(({ bad }) => {
      maxBad = bad > maxBad ? bad : maxBad;
    });
    const objPoorApplicationClass = poorApplicationClasses.find(
      ({ bad }) => bad === maxBad
    );
    return objPoorApplicationClass
      ? objPoorApplicationClass.application_class
      : null;
  }

  return (
    <HbrCard className="qoe-distribution-view">
      <div
        className={
          props.reporting ? "qoe-distributions" : css["qoe-distribution"]
        }
        data-cy="qoeDistributionView"
      >
        {!props.reporting && (
          <div className="title-block">
            <div className="flex-items">
              <div className="hbr-type-h2">
                <span className="qoeWordSpacing">{i18n.qoeDistributionViewQoE}</span>
              </div>
              <HbrTooltip
                content={i18n.qoeDistributionViewTooltip}
                placement="top"
                style={{marginLeft:"5px"}}
              >
                <HbrIcon
                  className="qoeTitleIcon"
                  name="info"
                  sentiment="neutral"
                ></HbrIcon>
              </HbrTooltip>
            </div>
          </div>
        )}
        {props.reporting && (
          <p className="title-text">
            {maxPoorApps
              ? format(i18n.qoeDistributionViewSLA, {
                object: maxPoorApps
              })
              : ""}
          </p>
        )}
        {loader === true ? (
          <div className="home-spinner-25" data-cy="spinner-componentQoEDistributionView">
            <Spinner />
          </div>
        ) : (
          <div className="qoe-db">
            {loader === false &&
              appClassData &&
              appClassData.length &&
              !errorAppClass ? (
              <div className="content-block">
                <DnxBar
                  data={appClassData}
                  names={names}
                  height={"240px"}
                  categoryAxis={{
                    labelRotation: 45
                  }}
                  ytitle={i18n.qoeDistributionViewAppCount}
                  stack={"regular"}
                  columnwidth
                  legend={{ enabled: false }}
                  barcolor={[
                    statusColors.good,
                    statusColors.fair,
                    statusColors.poor,
                    statusColors.gray
                  ]}
                  graphs={getBarChartballoonFunctionContent(
                    ["Good", "Fair", "Poor", "unknown"],
                    [
                      statusColors.good,
                      statusColors.fair,
                      statusColors.poor,
                      statusColors.gray
                    ]
                  )}
                />
              </div>
            ) : errorAppClass ? (
              <Suspense fallback={<Spinner />}>
                <ErrorComponent
                  width={"110px"}
                  className={"super-small-dashlet-error"}
                  errorCode={
                    globalFilter.applicationAggregateData.resObj
                      .errorCode
                  }
                  message={
                    globalFilter.applicationAggregateData.resObj
                      .message
                  }
                />
              </Suspense>
            ) : (
              <Suspense fallback={<Spinner />}>
                <NoDataAvailable
                  text={i18n.applicationDashboardNoData}
                />
              </Suspense>
            )}
            <ChartLegend applicationQoe={true} includeQuality {...props} />
          </div>
        )}
      </div>
    </HbrCard>
  );
}

QoeDistributionView.propTypes = {
  actions: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,
  reporting: PropTypes.bool
};
QoeDistributionView.defaultProps = {
  reporting: false
};
export default reduxContext.withProvider(
  connect(mapDispatchToProps)(AppHOC(QoeDistributionView))
);
