import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//reducer files
import reduxContext from "../reducer";
import * as actions from "../actions";

//style
import css from "../applicationsMagneticStyle.less";

//components 
import AppTable from "./AppTable";
import AppHOC from "../../../generics/AppHOC";
import { parseSelections } from "../../../config/apiParsers/applications";
import { useMount } from "../../../utils/genericCommon";

import { getCustomListApi } from "../../../utils/common";
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({
  applications: state.vanalytics.applications
});

const [HbrCard] = reactWrapper(["hbr-card"]);

const ApplicationsTableView = (props) => {
  const { globalFilter, timeFilter } = props;
  const { currentTimeStamp, selectedSite } = globalFilter;
  const [appClassDataPrev, setAppClassDataPrev] = useState(false); 
  const mount = useMount();

  useEffect(() => {
    if (mount.initMount) {
      mount.initMount = false;
    } else {
      if (!props?.reporting) {
        getData(timeFilter);
      }
    }

    displayAppsTableView(
      props.actions,
      globalFilter.timeFilter
    );
    setAppClassDataPrev(false);

    if (globalFilter?.app_data_with_family_sla?.length > 0 &&
      !appClassDataPrev
    ) {
      getSelectedAppsData();
    } else if (globalFilter.app_data_with_family_sla?.length == 0 &&
      !appClassDataPrev
    ) {
      props.setSelectedAppList([]);
      props.setSelectedAppListDefault([]);
    }
  }, [currentTimeStamp]);

  useEffect(() => { }, [selectedSite]);

  const getSelectedAppsData = () => {
    const selectedAppNames = globalFilter.app_data_with_family_sla
      .slice(0, 5)
      .map(app => app.application);
    const topAppsByUsage = parseSelections(selectedAppNames);
    props.setSelectedAppList(topAppsByUsage);
    props.setSelectedAppListDefault(topAppsByUsage);
    setAppClassDataPrev(true)
  };

  const getData = () => {
    props.setAppsWithFamilySLA(undefined);
    getCustomListApi(props);
  };

  const displayAppsTableView = (actions, timeFilter) => {
    const { setSelectedTimeFilter } = actions;
    setSelectedTimeFilter("TIMEFILTER", { timeFilter: timeFilter });
  }

  return (
    <div
      className={props.reporting ? css["top50-apps"] : css["appl-table"]}
      data-cy="applicationTableView"
    >
      <HbrCard className="application-dashboard-card">
       <AppTable {...props} />
      </HbrCard>
    </div>
  );

}

ApplicationsTableView.propTypes = {
  actions: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,
  applications: PropTypes.object.isRequired,
  setAggregateAppData: PropTypes.func.isRequired,
  setAppFamilySLA: PropTypes.func.isRequired,
  setAppsWithFamilySLA: PropTypes.func.isRequired,
  reporting: PropTypes.bool,
  timeFilter: PropTypes.object,
  setSelectedAppList: PropTypes.func.isRequired,
  setSelectedAppListDefault: PropTypes.func.isRequired
};
ApplicationsTableView.defaultProps = {
  reporting: false,
  timeFilter: {}
};
export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(ApplicationsTableView))
);