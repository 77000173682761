import React from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

const [HbrViewSwitcher, HbrRadioButton] =
  reactWrapper(["hbr-view-switcher", "hbr-radio-button"]);
const qoeHealth = [
  { key: "all", value: "All QoE" },
  { key: "good", value: "Good" },
  { key: "fair", value: "Fair" },
  { key: "poor", value: "Poor" },
  { key: "gray", value: "Unknown" }
];

const QoEFilter = ({ value, onChange, loader }) => (
  <HbrViewSwitcher value={value} onClick={onChange}>
    {qoeHealth.map(({ key, value }) => (
      <HbrRadioButton key={key} value={key} disabled={loader}>
        {value}
      </HbrRadioButton>
    ))}
  </HbrViewSwitcher>
);

QoEFilter.defaultProps = {
  loader: false
};

QoEFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loader: PropTypes.bool
};

export default QoEFilter;
