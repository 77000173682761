import React, { useState, useEffect, useRef, Suspense } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//i18n file
import i18n from "amdi18n-loader!../../nls/i18n";

//reducers
import reduxContext from "../../../generics/AppHOC/reducer";

//components
import Spinner from "../../../common/Spinner";
import TrendAnalysis from "../../sidebar/TrendAnalysis";
import VqoeLegend from "./VqoeLegend";
import ApplicationsTableView from "../ApplicationsTableView";
import AppHoc from "../../../generics/AppHOC";
import QoeDistributionView from "../QoeDistributionView";
import TrendingAppsView from "../TrendingAppsView";
import TreeMap from "./TreeMap";
import QoEComponent from "../../../common/QoEComponent";
import { useMount } from "../../../utils/genericCommon";
import { getCustomListApi } from "../../../utils/common";

const ErrorComponent = React.lazy(() =>
  import("../../../common/ErrorComponent")
);
const NoDataAvailable = React.lazy(() =>
  import("../../../common/NoDataAvailable")
);

//style
import css from "../applicationsMagneticStyle.less";

//utils
import { getUsageConfig, getUsageNQoEMetrics } from "./config/donut/usage";
import { getQoEconfig } from "./config/donut/qoe";
import { formatBytes } from "../../../utils/format";
import { TOGGLER, COLOR } from "../../../utils/enums";

const [HbrCard, HbrDrawer, HbrButton, HbrDonutChart, HbrTooltip, HbrIcon] = reactWrapper([
  "hbr-card",
  "hbr-drawer",
  "hbr-button",
  "hbr-donut-chart",
  "hbr-tooltip",
  "hbr-icon"
]);

const mapStateToProps = state => ({
  applications: state.vanalytics.app
});

const ApplicationExperienceView = props => {
  const [loader, setLoader] = useState(true);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [chartDataError, setChartDataError] = useState(false);
  const setIntervalRefs = useRef(null);
  const { app_data_top_usage, app_data_with_family_sla, applicationAggregateData, selectedOverlay } = props.applications.globalFilter;
  const [appExperienceData, setAppExperienceData] = useState(null);
  const [legendData, setLegendData] = useState({});
  const { toggleView, currentTimeStamp } = props.globalFilter;
  const mount = useMount();
  const chartData = useRef({
    accordionChartData: null,
    isEmpty: false,
    error: null
  })

  useEffect(() => {
    setChartDataError(false);

    if (!props.reporting && ((applicationAggregateData?.resObj?.errorObject instanceof Object) === true)) {
      setChartDataError(true);
      setLoader(false);
    }
  }, [applicationAggregateData]);

  useEffect(() => {
    if (mount.initMount) {
      mount.initMount = false;
      props.setPageToggle(TOGGLER.CARD);
      if (app_data_top_usage === undefined && selectedOverlay !== null) {
        props.setAppsWithFamilySLA(undefined);
        getCustomListApi(props);
      }
    } else {
      if (!props?.reporting && (toggleView === TOGGLER.CARD || toggleView === undefined)) {
        props.setAppsWithFamilySLA(undefined);
        getCustomListApi(props);
      }
    }
  }, [
    currentTimeStamp
  ])

  useEffect(() => {
    setLoader(true);

    if (
      app_data_with_family_sla !=
      undefined &&
      app_data_with_family_sla?.length > 0
    ) {
      //case where widget has data
      let top20 = [];
      top20 = app_data_with_family_sla.slice(
        0,
        20
      );

      top20.forEach(data => {
        const usagePercentage =
          (data.usage / app_data_top_usage) *
          100;

        if (usagePercentage < 1) {
          //if usage is less than 1% then set it to 1% to show in the chart
          data.usage = app_data_top_usage / 100;
          data.usage_percentage = usagePercentage;
        } else {
          data.usage_percentage = usagePercentage;
        }
      });

      if (top20 && top20.length > 0) {
        chartData.current.accordionChartData = top20;
        setAppExperienceData(top20);
      }

      const applicationsUsageNQoEData = getUsageNQoEMetrics(
        app_data_with_family_sla,
        app_data_top_usage
      );

      let emptyData = true;
      const usage = {
        good: 0,
        fair: 0,
        poor: 0,
        unknown: 0
      };
      const vqoe = {
        good: 0,
        fair: 0,
        poor: 0,
        unknown: 0
      };

      if (applicationsUsageNQoEData?.vqoe_summary) {
        const legendQoe = {};
        const legendUsage = {};
        let totalAppCount = 0;

        applicationsUsageNQoEData.vqoe_summary.map(sum => {
          if (sum.app_count > 0) {
            emptyData = false;
            totalAppCount += sum.app_count;
          }
        });

        if (!emptyData) {
          chartData.current.isEmpty = false;

          applicationsUsageNQoEData.vqoe_summary.map(qoe => {
            let qualityKey = qoe.vqoe;

            if (qoe.vqoe === COLOR.GRAY) {
              qualityKey = COLOR.UNKNOWN;
            }

            if (qoe.app_count > 0) {
              vqoe[qualityKey] = qoe.app_count;

              legendQoe[qualityKey] = {
                count: qoe.app_count,
                percentage: qoe.vqoe_percentage.toFixed(1)
              }
            }
          });

          if (applicationsUsageNQoEData.usage_summary) {
            applicationsUsageNQoEData.usage_summary.map(u => {
              let usageKey = u.vqoe;

              if (u.vqoe === COLOR.GRAY) {
                usageKey = COLOR.UNKNOWN;
              }

              usage[usageKey] = u.usage;

              if (u.usage > 0) {
                legendUsage[usageKey] = {
                  usage: formatBytes(u.usage),
                  percentage: u.vqoe_percentage.toFixed(1)
                }
              }
            });
          }
        }

        // set the data for chart 1 (QoE chart) and 2 (total usage)
        chartData.current.vqoe = vqoe;
        chartData.current.usage = usage;
        chartData.current.totalAppCount = totalAppCount;
        chartData.current.legendQoe = legendQoe;
        chartData.current.legendUsage = legendUsage;
        setLegendData({ legendUsage: legendUsage, legendQoe: legendQoe })
      }
      setLoader(false);

    } else if (Array.isArray(app_data_with_family_sla) && app_data_with_family_sla.length === 0) {
      //case the widget has no data
      chartData.current.isEmpty = true;
      setLoader(false);
    }
    if (props.reporting) {
      clearTimeout(setIntervalRefs.current);
    }
  }, [
    app_data_with_family_sla
  ]);

  useEffect(
    () => () => {
      clearTimeout(setIntervalRefs.current);
    },
    []
  );

  const onOpen = () => {
    setSidebarVisible(true);
  };

  const onClose = e => {
    if (e.target.className?.includes("time-filter-select"))
      e.preventDefault();
    else setSidebarVisible(false);
  };

  if (toggleView === TOGGLER.LIST) {
    return <ApplicationsTableView />
  }

  return (
    <>
      <HbrCard
        id="non-interactive"
        slot="label"
        className="app-experience-widget-hbr-card"
      >
        <div
          className={
            props.reporting ? "application-overview" : css["app-experience-view"]
          }
          data-cy="appExperienceView"
        >
          {sidebarVisible && !props.reporting && (
            <HbrDrawer
              overlay={true}
              label={i18n.appExperienceTrend}
              open
              onHbr-hide={onClose}
            >
              <TrendAnalysis />
            </HbrDrawer>
          )}
          {loader === false && !props.reporting && (
            <div className="title-block" data-cy="appExperienceViewTitle">
              <span className="hbr-type-h2">
                {i18n.appExperienceApplication}
              </span>
              {loader === false && (
                <div className="trend-analysis">
                  <HbrButton variant="text" onClick={onOpen}>
                    {i18n.trendingAppsViewTrendAnalysis}
                  </HbrButton>
                </div>
              )}
            </div>
          )}
          {loader === true && <div className="home-spinner-25"><Spinner /></div>}
          {chartDataError === true ?
            (
              <Suspense fallback={<Spinner />}>
                <ErrorComponent
                  width={"110px"}
                  className={"super-small-dashlet-error"}
                  errorCode={applicationAggregateData?.resObj?.errorCode}
                  message={applicationAggregateData?.resObj?.message}
                />
              </Suspense>
            ) :
            chartData.current.isEmpty ?
              <Suspense fallback={<Spinner />}><div id="isLoaded_chart"><NoDataAvailable text={i18n.applicationDashboardNoData} /></div></Suspense> :
              loader === false && chartData.current.accordionChartData &&
              <div
                className={props.reporting ? "chart-container" : "content-block"}
                data-cy="appExperienceViewContent"
              >
                <>
                  <div className="app-experience-chart-1">
                    <div className="qoe-title">{i18n.appExperienceQOE}
                      <div>
                        <HbrTooltip
                          content={i18n.appExperienceQualityEperience}
                          placement="top"
                        >
                          <HbrIcon
                            className=""
                            name="info"
                            sentiment="neutral"
                          />
                        </HbrTooltip>
                      </div>
                    </div>
                    <HbrDonutChart
                      config={getQoEconfig(chartData.current)}
                      width="100%"
                      height="100%"
                    >
                    </HbrDonutChart>
                    <div className="qoe-legend">
                      {app_data_top_usage && legendData.legendQoe && Object.keys(chartData.current.legendQoe).map((qoe, key) => (
                        <QoEComponent
                          key={key}
                          iconType={qoe}
                          qoeScore={""}
                          qoeTextValue={`${qoe} - ${chartData.current.legendQoe[qoe].percentage}% (${chartData.current.legendQoe[qoe].count})`}
                        />
                      ))
                      }
                    </div>
                  </div>
                  <div className="app-divider"></div>
                  <div className="app-experience-chart-2">
                    <div>{i18n.appExperienceUSAGE}</div>
                    <HbrDonutChart
                      config={getUsageConfig(chartData.current.usage, app_data_top_usage)}
                      width="100%"
                      height="100%"
                    >
                    </HbrDonutChart>
                    <div className="usage-legend">
                      {app_data_top_usage && legendData.legendUsage && Object.keys(chartData.current.legendUsage).map((usage, key) => (
                        <QoEComponent
                          key={key}
                          iconType={usage}
                          qoeScore={""}
                          qoeTextValue={`${usage} - ${chartData.current.legendUsage[usage].percentage}% (${chartData.current.legendUsage[usage].usage})`}
                        />
                      ))
                      }
                    </div>
                  </div>
                </>
                <TreeMap data={[...appExperienceData]} data-cy="tree-map" selectedSite={props?.applications?.globalFilter?.selectedSite}></TreeMap>
              </div>
          }
          <div className="footer-block" data-cy="appExperienceViewFooter">
            <VqoeLegend />
          </div>
        </div>
      </HbrCard>
      <div className="trending-app-row">
        <div className="distribution-app"><QoeDistributionView /></div>
        <div className="trending-app"><TrendingAppsView /></div>
      </div>
    </>);
};
ApplicationExperienceView.propTypes = {
  applications: PropTypes.object.isRequired,
  reporting: PropTypes.bool,
  timeFilter: PropTypes.object,
  globalFilter: PropTypes.object,
  setAppsWithFamilySLA: PropTypes.func,
  setPageToggle: PropTypes.func
};

ApplicationExperienceView.defaultProps = {
  reporting: false,
  timeFilter: {}
};
export default reduxContext.withProvider(
  connect(mapStateToProps, null)(AppHoc(ApplicationExperienceView))
);
