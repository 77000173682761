import Authorization from "../../generics/authorization";
import TabNavigation from "../../common/TabNavigation";
import PageToggle from "../../common/PageToggle";
import TimeStampFilterComponent from "../../common/TimeStampFilterComponent";
import ApplicationExperienceView from "./ApplicationExperienceView";

const xValue = 0;
const wValue = 12;

const pageProps = {
  filterDashlets: [
    {
      component: TabNavigation,
      name: "tab-nav",
      layout: { x: xValue, y: 0, w: 10.5, h: 9 }
    },
    {
      component: PageToggle,
      name: "all-apps-toggle",
      layout: { x: 12, y: 4, w: 2.5, h: 0 }
    },
    {
      component: TimeStampFilterComponent,
      name: "time-filter",
      layout: { x: xValue, y: 10, w: wValue, h: 2 }
    }
  ],
  dashlets: [{
    component: ApplicationExperienceView,
    name: "app-experience-view",
    layout: { x: xValue, y: 0, w: wValue, h: 23 },
  }]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
